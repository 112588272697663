import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class BiService extends Thoth {
    getAllCustomers() {
        return this.fetch({
            url: '/dashboard/customers',
        });
    }

    getList() {
        return this.fetch({
            url: '/dashboard/blacklist',

        });
    }

    addInBlacklist(data) {
        return this.fetch({
            url: '/dashboard/blacklist',
            method: 'POST',
            data: {
                data,
            },
        });
    }

    deleteInBlacklist(ids) {
        return this.fetch({
            url: '/dashboard/blacklist',
            method: 'DELETE',
            data: {
                data: ids,
            },
        });
    }

    clientSacker({
        page, pageSize, name, document,
    }) {
        return this.fetch({
            url: '/client-sackers',
            params: {
                page, page_size: pageSize, name, document,
            },
        });
    }

    changeReceiveDashboard(data) {
        return this.fetch({
            url: '/client-sackers',
            method: 'PUT',
            data,
        });
    }

    sendToken(data) {
        return this.fetch({
            method: methods.POST,
            url: '/dashboard/task',
            data,
        });
    }
}

export default new BiService();
