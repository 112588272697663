<template>
  <b-container
    fluid
  >
    <b-card>
      <Table
        :data="tableData"
        @updated="handleUpdated"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard,
} from 'bootstrap-vue';

import Table from './Components/Table.vue';

import BiService from '@/service/bi/index';

export default {
    components: {
        BContainer, Table, BCard,
    },

    data() {
        return {
            tableData: [],
            newItems: [],
            deletedItems: [],
            company: null,
            type: null,
            customers: [],
            currentPage: 1,
            perPage: 10,
            totalRows: 1,
            componentKey: 1,
            companies: [],
        };
    },

    computed: {
        items() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.tableData.filter(item => item.can_receive_dashboard).sort((a, b) => a.name?.localeCompare(b.name));
        },
    },

    mounted() {
        this.onMounted();
    },

    methods: {
        async handleUpdated(data) {
            this.callLoading(true);
            await BiService.changeReceiveDashboard({
                give: data.can_receive_dashboard ? [data.id] : [],
                remove: !data.can_receive_dashboard ? [data.id] : [],
            });
            this.callLoading(false);
        },
    },
};

</script>
