var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-input',{staticClass:"mb-1",attrs:{"placeholder":"Nome"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-input',{staticClass:"mb-1",attrs:{"placeholder":"Documento"},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}})],1)],1),_c('b-table',{staticClass:"text-center",attrs:{"per-page":_vm.perPage,"items":_vm.tableData,"filter":_vm.filter,"fields":[{ label: 'Nome', key: 'name'}, { label: 'DOCUMENTO', key: 'document'}, { label: 'Ações', key: 'action'}]},scopedSlots:_vm._u([{key:"cell(document)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("tax_id")(item.document))+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":item.can_receive_dashboard ? 'danger' : 'primary'},on:{"click":function () {
          item.can_receive_dashboard = !item.can_receive_dashboard
          _vm.$emit('updated', {
            id: item.id,
            document: item.document,
            can_receive_dashboard: item.can_receive_dashboard
          })
        }}},[_vm._v(" "+_vm._s(item.can_receive_dashboard ? 'Remover' : 'Adicionar')+" ")])]}}])}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.length,"per-page":_vm.perPage,"align":"center","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }