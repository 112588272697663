<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-input
          v-model="name"
          placeholder="Nome"
          class="mb-1"
        />
      </b-col>

      <b-col md="6">
        <b-form-input
          v-model="document"
          placeholder="Documento"
          class="mb-1"
        />
      </b-col>
    </b-row>

    <b-table
      :per-page="perPage"
      class="text-center"
      :items="tableData"
      :filter="filter"
      :fields="[{ label: 'Nome', key: 'name'}, { label: 'DOCUMENTO', key: 'document'}, { label: 'Ações', key: 'action'}]"
    >
      <template #cell(document)="{ item }">
        {{ item.document | tax_id }}
      </template>

      <template #cell(action)="{ item }">
        <b-button
          :variant="item.can_receive_dashboard ? 'danger' : 'primary'"
          @click="() => {
            item.can_receive_dashboard = !item.can_receive_dashboard
            $emit('updated', {
              id: item.id,
              document: item.document,
              can_receive_dashboard: item.can_receive_dashboard
            })
          }"
        >
          {{ item.can_receive_dashboard ? 'Remover' : 'Adicionar' }}
        </b-button>
      </template>

    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="length"
      :per-page="perPage"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import {
    BTable, BPagination, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue';

import BiService from '@/service/bi/';

export default {
    components: {
        BTable, BPagination, BFormInput, BRow, BCol, BButton,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            filter: '',
            currentPage: 1,
            perPage: 10,
            length: 1,
            tableData: [],
            document: '',
            name: '',
        };
    },

    watch: {
        currentPage() {
            this.refetch();
        },

        document() {
            this.refetch();
        },
        name() {
            this.refetch();
        },
    },

    mounted() {
        this.refetch();
    },

    methods: {
        async refetch() {
            this.callLoading(true);
            const { data: { meta, data } } = await BiService.clientSacker({
                page: this.currentPage, pageSize: this.perPage, document: this.document, name: this.name,
            });

            this.length = meta.total;
            this.tableData = data;
            this.callLoading(false);
        },
    },
};
</script>
